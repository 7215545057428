@font-face {
    font-family: Poppins;
    src: url("../assets/fonts/Poppins-Regular.ttf");
}

:root {
    --ion-font-family: 'Poppins';

    --ion-color-primary: #073637;
    --ion-color-primary-rgb: 7,54,55;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #063030;
    --ion-color-primary-tint: #204a4b;

    --ion-color-secondary: #56b9a3;
    --ion-color-secondary-rgb: 86, 185, 163;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #4ca38f;
    --ion-color-secondary-tint: #67c0ac;

    --ion-color-tertiary: #054a54;
    --ion-color-tertiary-rgb: 5,74,84;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #04414a;
    --ion-color-tertiary-tint: #1e5c65;

    --ion-color-success: #56b9a3;
    --ion-color-success-rgb: 86, 185, 163;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #4ca38f;
    --ion-color-success-tint: #67c0ac;

    --ion-color-warning: #F5F749;
    --ion-color-warning-rgb: 245, 247, 73;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #d8d940;
    --ion-color-warning-tint: #f6f85b;

    --ion-color-danger: #ce113a;
    --ion-color-danger-rgb: 206, 17, 58;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #b50f33;
    --ion-color-danger-tint: #d3294e;

    --ion-color-very-dark: #323232;
    --ion-color-very-dark-rgb: 42, 44, 43;
    --ion-color-very-dark-contrast: #ffffff;
    --ion-color-very-dark-contrast-rgb: 255, 255, 255;
    --ion-color-very-dark-shade: #252726;
    --ion-color-very-dark-tint: #3f4140;

    --ion-color-dark: #4E4E4E;
    --ion-color-dark-rgb: 78, 78, 78;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #454545;
    --ion-color-dark-tint: #606060;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-medium-light: #dfdfdf;
    --ion-color-medium-light-rgb: 223, 223, 223;
    --ion-color-medium-light-contrast: #000000;
    --ion-color-medium-light-contrast-rgb: 0, 0, 0;
    --ion-color-medium-light-shade: #c4c4c4;
    --ion-color-medium-light-tint: #e2e2e2;

    --ion-color-light: #FFFFFF;
    --ion-color-light-rgb: 255, 255, 255;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #e0e0e0;
    --ion-color-light-tint: #ffffff;

    --ion-color-orange: #FF9800;
    --ion-color-orange-rgb: 255, 152, 0;
    --ion-color-orange-contrast: #000000;
    --ion-color-orange-contrast-rgb: 0, 0, 0;
    --ion-color-orange-shade: #e08600;
    --ion-color-orange-tint: #ffa21a;

    --ion-color-green: #4CAF50;
    --ion-color-green-rgb: 76, 175, 80;
    --ion-color-green-contrast: #000000;
    --ion-color-green-contrast-rgb: 0, 0, 0;
    --ion-color-green-shade: #439a46;
    --ion-color-green-tint: #5eb762;

    --ion-color-blue: #2196F3;
    --ion-color-blue-rgb: 33, 150, 243;
    --ion-color-blue-contrast: #ffffff;
    --ion-color-blue-contrast-rgb: 255, 255, 255;
    --ion-color-blue-shade: #1d84d6;
    --ion-color-blue-tint: #37a1f4;

    --ion-color-dark-blue: #1A2087;
    --ion-color-dark-blue-rgb: 26, 32, 135;
    --ion-color-dark-blue-contrast: #ffffff;
    --ion-color-dark-blue-contrast-rgb: 255, 255, 255;
    --ion-color-dark-blue-shade: #171c77;
    --ion-color-dark-blue-tint: #313693;
}

.ion-color-primary {
    --ion-color-base: var(--ion-color-primary);
    --ion-color-base-rgb: var(--ion-color-primary-rgb);
    --ion-color-contrast: var(--ion-color-primary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
    --ion-color-shade: var(--ion-color-primary-shade);
    --ion-color-tint: var(--ion-color-primary-tint);
}

.ion-color-very-dark {
    --ion-color-base: var(--ion-color-very-dark);
    --ion-color-base-rgb: var(--ion-color-very-dark-rgb);
    --ion-color-contrast: var(--ion-color-very-dark-contrast);
    --ion-color-contrast-rgb: var(--ion-color-very-dark-contrast-rgb);
    --ion-color-shade: var(--ion-color-very-dark-shade);
    --ion-color-tint: var(--ion-color-very-dark-tint);
}

.ion-color-dark {
    --ion-color-base: var(--ion-color-dark);
    --ion-color-base-rgb: var(--ion-color-dark-rgb);
    --ion-color-contrast: var(--ion-color-dark-contrast);
    --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb);
    --ion-color-shade: var(--ion-color-dark-shade);
    --ion-color-tint: var(--ion-color-dark-tint);
}

.ion-color-medium-light {
    --ion-color-base: var(--ion-color-medium-light);
    --ion-color-base-rgb: var(--ion-color-medium-light-rgb);
    --ion-color-contrast: var(--ion-color-medium-light-contrast);
    --ion-color-contrast-rgb: var(--ion-color-medium-light-contrast-rgb);
    --ion-color-shade: var(--ion-color-medium-light-shade);
    --ion-color-tint: var(--ion-color-medium-light-tint);
}

.ion-color-light {
    --ion-color-base: var(--ion-color-light);
    --ion-color-base-rgb: var(--ion-color-light-rgb);
    --ion-color-contrast: var(--ion-color-light-contrast);
    --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb);
    --ion-color-shade: var(--ion-color-light-shade);
    --ion-color-tint: var(--ion-color-light-tint);
}

.ion-color-orange {
    --ion-color-base: var(--ion-color-orange);
    --ion-color-base-rgb: var(--ion-color-orange-rgb);
    --ion-color-contrast: var(--ion-color-orange-contrast);
    --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
    --ion-color-shade: var(--ion-color-orange-shade);
    --ion-color-tint: var(--ion-color-orange-tint);
}

.ion-color-green {
    --ion-color-base: var(--ion-color-green);
    --ion-color-base-rgb: var(--ion-color-green-rgb);
    --ion-color-contrast: var(--ion-color-green-contrast);
    --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
    --ion-color-shade: var(--ion-color-green-shade);
    --ion-color-tint: var(--ion-color-green-tint);
}

.ion-color-blue {
    --ion-color-base: var(--ion-color-blue);
    --ion-color-base-rgb: var(--ion-color-blue-rgb);
    --ion-color-contrast: var(--ion-color-blue-contrast);
    --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
    --ion-color-shade: var(--ion-color-blue-shade);
    --ion-color-tint: var(--ion-color-blue-tint);
}
